@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --raisin-black: #231f20;
  --blue-sapphire: #05668d;
  --white-text: #ffffff;
  --page-bg: #eeeeee;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding);
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
  background-size: 0 0.2rem;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(15, 33, 169, 1) 35%,
    rgba(126, 158, 255, 1) 100%
  );
}

.link-underline:hover {
  background-size: 100% 0.2rem;
  background-position: 0 100%;
}
